import { useRouteQuery } from '@vueuse/router';

import { fetchComplaintsService } from '@Services/complaint.service';
import type { IComplaint } from '@Models/Complaint/complaint.model';
import ComplaintFilterField from '@Models/enums/complaintFilterFileld';
import { type TSort, ESortDirection } from '~coreTypes/sort.model';

import EComplaintsTypes from '@Enums/complaintsTypes';
import EComplaintsStatus from '@Enums/complaintsStatus';
import EComplainantTypes from '@Enums/complainantTypes';

const state = reactive({
  complaints: [] as IComplaint[],
  originalComplaints: [] as IComplaint[]
});

export const useComplaints = () => {
  const { complaints, originalComplaints } = toRefs(state);

  const { $i18n, $routeNames } = useNuxtApp();

  function setComplaintTitle(type: EComplaintsTypes) {
    const setTitle: { [index: string]: string } = {
      [EComplaintsTypes.COURSE]: $i18n.t('bailiffItemDetails.courseTab'),
      [EComplaintsTypes.REFUSAL]: $i18n.t('bailiffItemDetails.refusalTab'),
      [EComplaintsTypes.KNOCK_DOWN]: $i18n.t('bailiffItemDetails.knockdownTab')
    };
    return setTitle[type];
  }

  function setComplaintStatus(status: EComplaintsStatus) {
    const setStatus: { [index: string]: string } = {
      [EComplaintsStatus.UNRECOGNIZED]: $i18n.t('complaints.status.unrecognized'),
      [EComplaintsStatus.SUBMITTED_TO_COURT]: $i18n.t('complaints.status.submittedToCourt'),
      [EComplaintsStatus.ACCEPTED_BY_BAILIFF]: $i18n.t('complaints.status.acceptedByBailiff'),
      [EComplaintsStatus.ACCEPTED_BY_JUDGE]: $i18n.t('complaints.status.acceptedByJudge'),
      [EComplaintsStatus.REJECTED_BY_JUDGE]: $i18n.t('complaints.status.rejected'),
      [EComplaintsStatus.DISMISSED_BY_JUDGE]: $i18n.t('complaints.status.dismissed')
    };

    return setStatus[status] || '';
  }

  function complaintIsKnockDown(complaintType: EComplaintsTypes) {
    return complaintType === EComplaintsTypes.KNOCK_DOWN;
  }

  function isComplaintUnrecognized(status: EComplaintsStatus) {
    return status === EComplaintsStatus.UNRECOGNIZED;
  }

  function isComplaintSubmittedToCourt(status: EComplaintsStatus) {
    return status === EComplaintsStatus.SUBMITTED_TO_COURT;
  }

  function isComplaintAcceptedByBailiff(status: EComplaintsStatus) {
    return status === EComplaintsStatus.ACCEPTED_BY_BAILIFF;
  }

  function setAdditionalDescription(complainantType: EComplainantTypes) {
    if (complainantType === EComplainantTypes.DEBTORS_PRINCIPAL || complainantType === EComplainantTypes.DEBTOR) {
      return $i18n.t('complaints.single.debtor');
    } else {
      return $i18n.t('complaints.single.creditor');
    }
  }

  function setInfoBarText(type: EComplaintsTypes) {
    return type === EComplaintsTypes.REFUSAL
      ? $i18n.t('complaints.single.modal.secondStep.info.refusalComplaint')
      : $i18n.t('complaints.single.modal.secondStep.info.otherComplaint');
  }

  const loader = ref(false);

  const headers = [
    {
      title: $i18n.t('bailiffComplaints.table.author'),
      key: ComplaintFilterField.AUTHOR
    },
    {
      title: $i18n.t('bailiffComplaints.table.createAt'),
      key: ComplaintFilterField.DATE_CREATED,
      sortable: true
    },
    {
      title: $i18n.t('bailiffComplaints.table.type'),
      key: ComplaintFilterField.COMPLAINT_TYPE
    },
    {
      title: $i18n.t('bailiffComplaints.table.status'),
      key: ComplaintFilterField.COMPLAINT_STATUS
    }
  ];

  const complaintsStatuses: { value: EComplaintsStatus; title: string }[] = [
    {
      value: EComplaintsStatus.UNRECOGNIZED,
      title: $i18n.t('complaints.status.unrecognized')
    },
    {
      value: EComplaintsStatus.SUBMITTED_TO_COURT,
      title: $i18n.t('complaints.status.submittedToCourt')
    },
    {
      value: EComplaintsStatus.ACCEPTED_BY_JUDGE,
      title: $i18n.t('complaints.status.acceptedByJudge')
    },
    {
      value: EComplaintsStatus.ACCEPTED_BY_BAILIFF,
      title: $i18n.t('complaints.status.acceptedByBailiff')
    },
    {
      value: EComplaintsStatus.REJECTED_BY_JUDGE,
      title: $i18n.t('complaints.status.rejected')
    },
    {
      value: EComplaintsStatus.DISMISSED_BY_JUDGE,
      title: $i18n.t('complaints.status.dismissed')
    }
  ];

  const complaintsTypesList: { value: EComplaintsTypes; title: string }[] = [
    {
      value: EComplaintsTypes.REFUSAL,
      title: $i18n.t('bailiffComplaints.filters.types.refusal')
    },
    {
      value: EComplaintsTypes.COURSE,
      title: $i18n.t('bailiffComplaints.filters.types.course')
    },
    {
      value: EComplaintsTypes.KNOCK_DOWN,
      title: $i18n.t('bailiffComplaints.filters.types.knockDown')
    }
  ];

  async function fetchComplaintsByAuctionId(auctionId: number) {
    loader.value = true;
    const response = await fetchComplaintsService(auctionId);
    if (response) {
      complaints.value = response;
      originalComplaints.value = complaints.value;
      filterComplaints();
    }
    loader.value = false;
  }

  function redirectToComplaint(auctionId: number, complaintId: number) {
    return {
      name: $routeNames.bailiff.auctions.auctionComplaint(auctionId, complaintId),
      params: { id: auctionId, complaintId: complaintId }
    };
  }

  function filterComplaints() {
    let filteredComplaintsByType: IComplaint[] = [];

    if (typesSelected.value) {
      filteredComplaintsByType = originalComplaints.value.filter((complaint) => {
        if (complaint.complaintType === typesSelected.value) return complaint;
      });
    } else {
      filteredComplaintsByType = originalComplaints.value;
    }

    if (statusSelected.value) {
      complaints.value = filteredComplaintsByType.filter((complaint) => {
        if (complaint?.complaintStatus === statusSelected.value) return complaint;
      });
    } else {
      complaints.value = filteredComplaintsByType;
    }
  }

  const statusSelected = useRouteQuery<EComplaintsStatus>(ComplaintFilterField.COMPLAINT_STATUS, undefined, {
    mode: 'push'
  });

  const typesSelected = useRouteQuery<EComplaintsTypes>(ComplaintFilterField.COMPLAINT_TYPE, undefined, {
    mode: 'push'
  });

  const isFilterApplied = computed(() => !!(statusSelected.value || typesSelected.value));

  async function changeSort(sortData: TSort) {
    const list = typesSelected.value || statusSelected.value ? complaints.value : originalComplaints.value;

    if (sortData.direction === ESortDirection.ASC) {
      list.sort((a, b) => {
        return Date.parse(a.dateCreated) - Date.parse(b.dateCreated);
      });
    } else if (sortData.direction === ESortDirection.DESC) {
      list.sort((a, b) => {
        return Date.parse(b.dateCreated) - Date.parse(a.dateCreated);
      });
    }

    complaints.value = list;
  }

  function setComplaintTypes(type: EComplaintsTypes) {
    const setType: Record<EComplaintsTypes, string> = {
      [EComplaintsTypes.COURSE]: $i18n.t('bailiffComplaints.filters.types.course'),
      [EComplaintsTypes.REFUSAL]: $i18n.t('bailiffComplaints.filters.types.refusal'),
      [EComplaintsTypes.KNOCK_DOWN]: $i18n.t('bailiffComplaints.filters.types.knockDown')
    };
    return setType[type];
  }

  const showFilter = computed(() => {
    if (loader.value) return true;

    if (complaints.value.length) return true;
    return !(!statusSelected.value && !typesSelected.value);
  });

  return {
    complaints,
    complaintsStatuses,
    complaintsTypesList,
    headers,
    isFilterApplied,
    loader,
    statusSelected,
    showFilter,
    typesSelected,
    changeSort,
    complaintIsKnockDown,
    fetchComplaintsByAuctionId,
    filterComplaints,
    isComplaintAcceptedByBailiff,
    isComplaintUnrecognized,
    isComplaintSubmittedToCourt,
    redirectToComplaint,
    setAdditionalDescription,
    setComplaintStatus,
    setComplaintTitle,
    setComplaintTypes,
    setInfoBarText
  };
};
